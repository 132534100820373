export const TOGGLE = {
  CHATBOT_WELCOME_MSG: 'CHATBOT_WELCOME_MSG',
  CONTACT_US_CHAT_OPEN: 'CONTACTUS_CHATBOT_OPEN',
  FAQGPT_SUMMARY: 'FAQGPT_SUMMARY',
  FAQGPT_SUMMARY_STREAM: 'FAQGPT_SUMMARY_STREAM',
  USER_FEEDBACK: 'USER_FEEDBACK',
  SUMMARY_SCREENING_WAVE_0: 'SUMMARY_SCREENING_WAVE_0',
  SUMMARY_SCREENING_WAVE_1: 'SUMMARY_SCREENING_WAVE_1',
  SUMMARY_SCREENING_WAVE_2: 'SUMMARY_SCREENING_WAVE_2',
  SUMMARY_SCREENING_WAVE_4: 'SUMMARY_SCREENING_WAVE_4',
  SUMMARY_SCREENING_WAVE_5: 'SUMMARY_SCREENING_WAVE_5',
  SUMMARY_SCREENING_MODAIS_WAVE_0: 'SUMMARY_SCREENING_MODAIS_WAVE_0',
  CHATBOT_CHANGE_EMAIL: 'CHATBOT_CHANGE_EMAIL',
  HOME_SECTIONS_LIST: 'NEW_HOME',
}
